import { useEffect, useState } from "react";
import useInterval from '@use-it/interval';

export default function DetalleEscaneo({ escaneados, personasEmbarcadas, vehiculosEmbarcados }) {
    const [state] = useState(JSON.parse(localStorage.getItem('estado')));
    const cruce_seleccionado = state['cruceSeleccionado'];
    const [estadoBotonCargarDatos, setEstadoBotonCargarDatos] = useState(true);
    const [totalPersonas, setTotalPersonas] = useState([{ total: 0, embarcados: 0 }, { total: 0, embarcados: 0 }]);
    const apiUrlCrucesTotales = process.env.REACT_APP_API_URL_CRUCES_TOTALES;

    const cargarDatos = async () => {
        setEstadoBotonCargarDatos(false);

        let id_cruce, id_tramo;

        // Asegúrate de que cruce_seleccionado esté correctamente definido
        const cruce_seleccionado = state.cruceSeleccionado;

        console.log('Tipo de cruce_seleccionado:', typeof cruce_seleccionado);
        console.log('Es array:', Array.isArray(cruce_seleccionado));
        console.log('Contenido de cruce_seleccionado:', cruce_seleccionado);

        if (Array.isArray(cruce_seleccionado)) {

            //! REVISA SI CRUCE SELECCIONADO SI ES UN ARRAY CON 3 OBJETOS 🤡

            id_cruce = cruce_seleccionado.slice(0, 3).map(item => item.id_cruce);
            id_tramo = cruce_seleccionado.slice(0, 3).map(item => item.id_tramo);

            while (id_cruce.length < 3) {
                id_cruce.push(null);
                id_tramo.push(null);
            }

        } else if (cruce_seleccionado && typeof cruce_seleccionado === 'object') {

            //! REVISA SI CRUCE SELECCIONADO ES UN OBJETO VÁLIDO 🤡
            
            console.log('Entró en el else if, cruce_seleccionado es un objeto');
            id_cruce = [cruce_seleccionado.id_cruce, null, null];
            id_tramo = [cruce_seleccionado.id_tramo, null, null];
        } else {

            //! REVISA SI CRUCE SELECCIONADO NO ES UN ARRAY NI UN OBJETO VÁLIDO 

            console.log('Entró en el else, cruce_seleccionado no es un array ni un objeto válido');
            id_cruce = [null, null, null];
            id_tramo = [null, null, null];
        }

        console.log('id_cruce:', id_cruce);
        console.log('id_tramo:', id_tramo);

        const payload = {
            id_cruce: id_cruce,
            id_tramo: id_tramo,
        };

        console.log('Payload:', payload);

        try {
            const response = await fetch(apiUrlCrucesTotales, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            setTotalPersonas(data);
        } catch (error) {
            console.error('Error al cargar los datos:', error);
        } finally {
            setEstadoBotonCargarDatos(true);
        }
    };

    useEffect(() => {
        cargarDatos();
    }, []);

    useInterval(() => {
        cargarDatos();
    }, 60000);

    return (
        <div className="w-full">
            <div className="w-full flex flex-wrap border-gray-300 border-b">
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                            <span className="uppercase text-sm font-black">Embarcados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{personasEmbarcadas ?? 0}</h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Pasajeros con este dispositivo
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                            </svg>
                            <span className="uppercase text-sm font-black">Rechazados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{(escaneados.length ?? 0) - (personasEmbarcadas + vehiculosEmbarcados)}</h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Tickets rechazados por este dispositivo
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-wrap border-gray-300 border-b">
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path fill="#fff" d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                            </svg>
                            <span className="uppercase text-sm font-black">Embarcados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{vehiculosEmbarcados ?? 0}</h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Vehículos con este dispositivo
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                            </svg>
                            <span className="uppercase text-sm font-black">Escaneados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{escaneados.length ?? "0"}</h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Tickets escaneados con este dispositivo
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full text-center border-b border-gray-300 py-3 bg-gray-400 shadow-inner">
                <h1 className="text-lg font-black uppercase text-white">Estado General</h1>
            </div>

            <div className="w-full flex">
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                            <span className="uppercase text-sm font-black">Embarcados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{totalPersonas[0].embarcados ?? 0}<span className="text-2xl text-gray-600">/ {totalPersonas[0].total ?? "?"}</span></h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Pasajeros en total
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="px-2 py-3 border-r border-gray-300">
                        <div className="flex justify-center items-center text-blue-900 space-x-2">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path fill="#fff" d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                            </svg>
                            <span className="uppercase text-sm font-black">Embarcados</span>
                        </div>
                        <div className="block w-full text-center">
                            <h2 className="text-5xl font-black text-yellow-600 py-3">{totalPersonas[1].embarcados ?? 0}<span className="text-2xl text-gray-600">/ {totalPersonas[1].total ?? "?"}</span></h2>
                        </div>
                        <div className="block w-full text-center text-xs text-gray-500">
                            Vehículos en total
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-3 w-full">
                <button disabled={!estadoBotonCargarDatos} onClick={cargarDatos} className={`block ${estadoBotonCargarDatos ? 'bg-yellow-600' : 'bg-gray-600'} w-11/12 mx-auto rounded text-white text-lg font-semibold py-2`}>Actualizar Datos</button>
            </div>
        </div>
    );
}